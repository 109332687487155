import React, { Component } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Case from "../components/Case";
import ReactPaginate from 'react-paginate';

import Helmet from "react-helmet";

class Cases extends Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }
  
    render() {
      return (
        <div id="cases">
          <Helmet>
            <title>Portfolio</title>
            <meta
              name="description"
              content="Oetang is een multidisciplinaire ontwerpstudio van leerervaringen. We werken volgens de methode van Learning Experience Design (LXD) waarbij we learner centered aan de slag gaan om leerproducten en leeroplossingen te ontwerpen. We doen dit zowel voor educatieve als professionele leeromgevingen."
            ></meta>
            <meta
              name="keywords"
              content="learning, leerervaring, design, leren, lxd, leerproduct, educatie, workplace, business, learning &amp; development, onderwijs, erfgoed, lifelong learning, learning experience design"
            ></meta>
          </Helmet>

          <Header onMenuToggle={this.toggleMobileMenu} headerBG="green" />
        
          <section className="about-us">
            <h1 className="about-us__title">
                Leerproducten <br></br> uit onze studio
            </h1>
            </section>

            <Case
                title="Recente <span>leerproducten</span><br>van onze studio"
                caseTitles={["blokkades", "hofbots", "thuistools"]}
            ></Case>
  
            <Footer
                page="home"
                cta="Dus, wanneer <span>beginnen we?</span>"
            ></Footer>
        </div>
      );
    }
  }
  
  export default Cases;